export const loadState = () => {
  try {
    let serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    
    //Add these lines to force the default page 'forest' to load when the user had selected 'coral'
    //Comment out these following lines to reactivate coral
    const newData = JSON.parse(serializedState);
    const brand = newData.mission.selectedMissionId;
    if(brand  === 'coral'){
      serializedState = {'mision': {'selectedMissionId':'forest'}};
    }

    return JSON.parse(serializedState);
  } catch (e) {
    /* log error */
  }

  return undefined;
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
    return true;
  } catch (e) {
    /* log error */
  }

  return false;
};
