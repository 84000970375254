const missionData = [
  {
    id: 'forest',
    title: 'Rainforests',
    color: '#8fbf4d',
    bg_image: 'https://gaiamissioncdn.org/images/themes/bg/forest-bg.png',
    avatar: 'https://gaiamissioncdn.org/images/themes/avatars/forest.svg',
    icon: 'las la-tree',
    searchMoto: 'Your searches helps to protect the Rainforests. Thank you!',
    ext: {
      title: "let's save the",
      objective: "save the rainforest",
      id: 'odlllojdopjjgikjdcfjfcnobcileonb',
      oneId: '103_5dd3186b7bb3253c81054212',
      url: 'https://chrome.google.com/webstore/detail/gaia-mission-search-and-s/odlllojdopjjgikjdcfjfcnobcileonb',
    },
    counter: {
      description: 'Help save and restore rainforests',
      unity: 'Acres',
      text: 'Forest Land Bought',
      value: 12365,
      rate: 3,
    },
    how: {
      title:
        'Acquire an acre of threatened tropical rainforest while you search the web',
      text1:
        'Gaia Mission finances the protection of tropical rainforest by your web searches',
      text2: 'Gaia Mission uses the proceeds to support rainforest protection',
    },
    stats: [
      {
        count: 1.2,
        unity: 'million',
        text: 'active users',
      },
      {
        count: 1246,
        unity: 'acres',
        text: 'of land bought',
      },
      {
        count: 56283,
        prefix: '$',
        unity: 'USD',
        text: 'invested',
      },
      {
        count: 45,
        prefix: '$',
        unity: 'USD',
        text: 'per acre invested',
      },
    ],
  },
  {
    id: 'ocean',
    title: 'Oceans',
    color: '#50a7b8',
    bg_image: 'https://gaiamissioncdn.org/images/themes/bg/ocean-bg.png',
    avatar: 'https://gaiamissioncdn.org/images/themes/avatars/ocean.svg',
    icon: 'las la-water',
    searchMoto: 'Thank you! Your search supports the end of plastic pollution.',
    ext: {
      title: "let's clean the",
      objective: "clean the oceans",
      id: 'aahfigdhlndogchddkcjoejfbenfpfoc',
      oneId: '103_5dd3188e7bb3253c874213a2',
      url: 'https://chrome.google.com/webstore/detail/gaia-mission-search-and-s/aahfigdhlndogchddkcjoejfbenfpfoc',
    },
    counter: {
      description: 'Help clean and protect the ocean',
      unity: 'Tons',
      text: 'Of Plastic removed',
      value: 78235,
      rate: 6,
    },
    how: {
      title: 'Clean up the ocean while you search the web',
      text1:
        'Gaia Mission funds the removal of trash in oceans with the help of your web searches',
      text2: 'Gaia Mission uses the proceeds to remove trash from the oceans',
    },
    stats: [
      {
        count: 1.2,
        unity: 'million',
        text: 'active users',
      },
      {
        count: 1.2,
        unity: 'million tonnes',
        text: 'of trash removed',
      },
      {
        count: 56283,
        prefix: '$',
        unity: 'USD',
        text: 'invested',
      },
      {
        count: 0.05,
        prefix: '$',
        unity: 'USD',
        text: 'per tonne invested',
      },
    ],
  },
  {
    id: 'wildlife',
    title: 'Wildlife',
    color: '#f89337',
    bg_image: 'https://gaiamissioncdn.org/images/themes/bg/wildlife-bg.png',
    avatar: 'https://gaiamissioncdn.org/images/themes/avatars/wildlife.svg',
    icon: 'las la-hippo',
    searchMoto: 'Your searches helps protect the habitats of endangered species.',
    ext: {
      title: "let's preserve the",
      objective: "preserve the wildlife",
      id: 'jpjklglhgohnkkbgmglkaccofbnooahf',
      oneId: '103_5dd318887bb3251ef00e1ce2',
      url: 'https://chrome.google.com/webstore/detail/gaia-mission-search-and-s/jpjklglhgohnkkbgmglkaccofbnooahf',
    },
    counter: {
      description: 'Help protect wildlife',
      unity: '',
      text: 'Wildlife Rangers backed',
      value: 1365,
      rate: 10,
    },
    how: {
      title: 'Protect wildlife while you search the web',
      text1:
        'Gaia Mission backs the preservation of wildlife from your web searches',
      text2: 'Gaia Mission uses the proceeds to back a ranger station',
    },
    stats: [
      {
        count: 1.2,
        unity: 'million',
        text: 'active users',
      },
      {
        count: 1500,
        unity: '',
        text: 'rangers backed',
      },
      {
        count: 56283,
        prefix: '$',
        unity: 'USD',
        text: 'invested',
      },
      {
        count: 450,
        prefix: '$',
        unity: 'USD',
        text: 'per ranger invested',
      },
    ],
  },
  {
    id: 'tree',
    title: 'Tree Planting',
    color: '#b48f11',
    bg_image: 'https://gaiamissioncdn.org/images/themes/bg/tree-bg.png',
    avatar: 'https://gaiamissioncdn.org/images/themes/avatars/tree.svg',
    icon: 'las la-seedling',
    searchMoto: 'Your search helps ensure sustainable forest management practices.',
    ext: {
      title: "let's help",
      objective: "help planting trees",
      id: 'gdaaodckdjkcbnhggnojcahaddkelhol',
      oneId: '103_5dd318827bb3255ec2141d02',
      url: 'https://chrome.google.com/webstore/detail/gaia-mission-search-and-p/gdaaodckdjkcbnhggnojcahaddkelhol',
    },
    counter: {
      description: 'Help conserve and protect our forests',
      unity: 'M',
      sup: 3,
      text: 'Water filtered',
      value: 32654,
      rate: 3,
    },
    how: {
      title: 'Planting trees while you search the web',
      text1:
        'Gaia Mission commits to tree planting with your web searches',
      text2: 'Gaia Mission uses the proceeds to fund  tree-planting charities',
    },
    stats: [
      {
        count: 1.2,
        unity: 'million',
        text: 'active users',
      },
      {
        count: 5,
        unity: 'facilities',
        text: 'of Water treatment built',
      },
      {
        count: 10,
        unity: 'projects',
        text: 'in progress',
      },
      {
        count: 506283,
        prefix: '$',
        unity: 'USD',
        text: 'invested',
      },
    ],
  },
  {
    id: 'animals',
    title: 'Animals',
    color: '#7c716b',
    bg_image: 'https://gaiamissioncdn.org/images/themes/bg/animals-bg.png',
    avatar: 'https://gaiamissioncdn.org/images/themes/avatars/animals.svg',
    icon: 'las la-dog',
    searchMoto: 'This search helps to support a better future for animals everywhere.',
    ext: {
      title: "let's protect the",
      objective: "protect the animals",
      id: 'handlcngbhbcefeffpimjceilhheakbg',
      oneId: '103_5dd318747bb3253e3e0fb742',
      url: 'https://chrome.google.com/webstore/detail/gaia-mission-search-and-s/handlcngbhbcefeffpimjceilhheakbg',
    },
    counter: {
      description: 'Help rescue and protect animals',
      unity: '',
      text: 'Animals rescued',
      value: 5365,
      rate: 12,
    },
    how: {
      title: 'Help protecting animals while you search the web',
      text1: 'Gaia Mission protects animals with your web searches',
      text2:
        'Gaia Mission uses the proceeds to support animals protection charities',
    },
    stats: [
      {
        count: 1.2,
        unity: 'million',
        text: 'active users',
      },
      {
        count: 126062,
        unity: '',
        text: 'animals rescued',
      },
      {
        count: 15632,
        unity: 'tonnes',
        text: 'of food provided',
      },
      {
        count: 56283,
        prefix: '$',
        unity: 'USD',
        text: 'invested',
      },
    ],
  },
  /*
  {
    id: 'coral',
    title: 'Coral Reefs',
    color: '#d84646',
    bg_image: 'https://gaiamissioncdn.org/images/themes/bg/coralreef-bg.png',
    avatar: 'https://gaiamissioncdn.org/images/themes/avatars/coral.svg',
    icon: 'las la-asterisk',
    searchMoto: 'Your searches helps coastal cleanup & community education worldwide.',
    ext: {
      title: "let's preserve",
      objective: "preserve the coral reefs",
      id: 'fodhipbllinlckmdkgjopnncimdanbal',
      oneId: '103_5dd3187a7bb3255ebc3c41c2',
      url: 'https://chrome.google.com/webstore/detail/gaia-mission-search-save/fodhipbllinlckmdkgjopnncimdanbal',
    },
    counter: {
      description: 'Help restore and protect coral reefs',
      unity: 'm',
      text: 'Of Coral Reef Restored',
      value: 2363,
      rate: 20,
    },
    how: {
      title: 'Preserve coral reefs while you search the web',
      text1: 'Gaia Mission protects our global treasure with your web searches',
      text2:
        'Gaia Mission uses the proceeds to raise funds for reef science & research projects',
    },
    stats: [
      {
        count: 1.2,
        unity: 'million',
        text: 'active users',
      },
      {
        count: 15054,
        unity: 'km2',
        text: 'of recovering live coral',
      },
      {
        count: 125365,
        prefix: '$',
        unity: 'USD',
        text: 'invested in reef research',
      },
      {
        count: 56283,
        prefix: '$',
        unity: 'USD',
        text: 'invested on water quality',
      },
    ],
  },
  */
];

const startDate = new Date('2018-11-01T00:00:00Z');

const counterDetails = {
  forest: {
    initialValue: 12365,
    startDate,
  },
  ocean: {
    initialValue: 78235,
    startDate,
  },
  wildlife: {
    initialValue: 1365,
    startDate,
  },
  tree: {
    initialValue: 32654,
    startDate,
  },
  animals: {
    initialValue: 5365,
    startDate,
  },
  coral: {
    initialValue: 2363,
    startDate,
  },
};

function getCurrentCounterValue(initlaValue, starteDate, rate) {
  let diff = (new Date().getTime() - starteDate.getTime()) / 1000;
  return initlaValue + Math.floor(diff / rate);
}

function initMissionsCounter(missionData) {
  return missionData.map(mission => {
    mission.counter.value = getCurrentCounterValue(
      counterDetails[mission.id].initialValue,
      counterDetails[mission.id].startDate,
      mission.counter.rate,
    );
    return mission;
  });
}

export function getMissionData() {
  return initMissionsCounter(missionData);
}

export function getMissionDataById(mid){
  const data = getMissionData();
  return data.find( mission => mission.id === mid);
}
