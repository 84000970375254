import React from 'react';
import ListingTitle from './components/ListingTitle';
import ListingHost from './components/ListingHost';
import ListingDescription from './components/ListingDescription';
import SiteLinks from './components/SiteLinks';

import './style.css';

const ResultLine = props => (
  <div className="sr-line">
    <ListingTitle target={props.clickUrl} text={props.title} />
    <ListingHost target={props.clickUrl} bidded={props.bidded} text={props.siteHost} />
    <ListingDescription text={props.description} />
    {
      (props.bidded && props.siteLinks) ?
      <SiteLinks links={props.siteLinks}></SiteLinks>
      :
      ''
    }
  </div>
);

export default ResultLine;
