import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import GaiaLogo from 'components/GaiaLogo';
import SearchBox from 'components/SearchBox';
import { connect } from 'react-redux';
import { getSelectedMission } from 'redux/reducers';
import OrganicResults from '../OrganicResults';
import BiddedResults from '../BiddedResults';
import {
  //getXmlSearchResults,
  getYPASearchResults,
  getYPANavElements,
} from 'common/YHelper';
//import { checkAb } from 'common/utils';
import PageNavigator from '../PageNavigator';
import Listingheader from '../Listingheader';
import Footer from 'components/Footer';
import AdblockDetected from 'components/AdblockDetected';
import AdblockerMessage from 'components/AdblockerMessage';


import './style.css';

class SearchResultPage extends Component {
  constructor(props) {
    super(props);
    this.state = { noYpaAlgoResults: false };
  }

  componentDidMount() {
    if (this.props.keyword) {
      this.onSearch(
        this.props.keyword,
        this.props.typeTag,
        this.props.wrp,
        this.props.nxt,
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.onSearch(
      nextProps.keyword,
      nextProps.typeTag,
      nextProps.wrp,
      nextProps.nxt,
    );
  }

  onSearch(keyword, typeTag, wrp, nxt) {
    window.scrollTo(0, 0);
    // checkAb()
    //   .then(() => {
    this.setState({ xmlSearch: false });
    getYPASearchResults(keyword, typeTag, wrp, this.onNoAlgoResults);
    this.setState({ navElements: getYPANavElements(parseInt(wrp)) });
    // })
    // .catch(e => {
    //   this.setState({ queryInProgress: true, xmlSearch: true });
    //   window.location = 'https://search.yahoo.com/search?p=' + encodeURIComponent(keyword)
    //   //getXmlSearchResults(keyword, typeTag, wrp, nxt).then(data => {
    //   //  this.setState({ queryInProgress: false });
    //   //  this.setState(data);
    //   //});
    // });
  }

  onNoAlgoResults = () => {
    this.setState({ noYpaAlgoResults: true });
  };

  render() {
    return (
      <div className="result-page">
        {this.state.queryInProgress && <div className="result-page-overlay" />}
        <div className="top-search-nav">
          <div className="top-search-nav-line1">
            <div className="header-logo logo-result-page">
              <a className="site-logo" href="/">
                <GaiaLogo styleName="svg-logo" width={18} height={18} />
                <div className="vertical-logo">
                  <span className="logo-text-1">GAIA</span>
                  <br />
                  <span className="logo-text-2">MISSION</span>
                </div>
              </a>
            </div>
            <SearchBox
              className="search-form-result-page"
              initialValue={this.props.keyword}
              typeTag={this.props.typeTag}
            />
            {/*
            <div className="result-page-mission-moto">
              {this.props.selectedMission.searchMoto} (
              <a target="_blank" href="/#howitworks">
                learn more
              </a>
              )
            </div>
            */}
          </div>
          <ul className="search-type-list">
            <li>
              <a className="active" href="#0">
                <i className="las la-search"></i>
                <FormattedMessage id="app.search.web"></FormattedMessage>
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href={
                  'https://news.search.yahoo.com/search?p=' + this.props.keyword
                }
                target="_blank"
              >
                <i className="las la-newspaper"></i>
                <FormattedMessage id="app.search.news"></FormattedMessage>
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href={
                  'https://images.search.yahoo.com/search/images?p=' +
                  this.props.keyword
                }
                target="_blank"
              >
                <i className="las la-image"></i>
                <FormattedMessage id="app.search.images"></FormattedMessage>
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href={
                  'https://video.search.yahoo.com/search/video?p=' +
                  this.props.keyword
                }
                target="_blank"
              >
                <i className="las la-play-circle"></i>
                <FormattedMessage id="app.search.videos"></FormattedMessage>
              </a>
            </li>
          </ul>
        </div>
        <div className="search-result-container">
          {!this.state.xmlSearch && (
            <React.Fragment>
              <div className="sr-web-result">
                <div id="ypaAdWrapper-TOP_AD"></div>
                {this.state.noYpaAlgoResults ? (
                  ''
                ) : (
                  <Listingheader keyword={this.props.keyword} />
                )}
                <AdblockDetected>
                  <AdblockerMessage selectedMission={this.props.selectedMission}></AdblockerMessage>
                </AdblockDetected>
                <div id="ypaAdWrapper-ALGO_MIDDLE"></div>
                <div id="ypaAdWrapper-BOTTOM_AD"></div>
              </div>
              {this.state.noYpaAlgoResults ? (
                ''
              ) : (
                <PageNavigator
                  keyword={this.props.keyword}
                  navElements={this.state.navElements}
                />
              )}
            </React.Fragment>
          )}
          {this.state.xmlSearch && this.state.organic && (
            <React.Fragment>
              {this.state.topBidded ? (
                <BiddedResults
                  keyword={this.props.keyword}
                  listing={this.state.topBidded}
                />
              ) : (
                ''
              )}
              <OrganicResults
                keyword={this.props.keyword}
                totalHits={this.state.totalHits}
                listing={this.state.organic}
              />
              {this.state.bottomBidded ? (
                <BiddedResults
                  keyword={this.props.keyword}
                  listing={this.state.bottomBidded}
                />
              ) : (
                ''
              )}
              <PageNavigator
                keyword={this.props.keyword}
                navElements={this.state.navElements}
              />
            </React.Fragment>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedMission: getSelectedMission(state),
});

const ThemedSearchResultPage = connect(mapStateToProps)(SearchResultPage);

export default ThemedSearchResultPage;
