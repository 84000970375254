const ab_flag_name = 'abd';
//const ab_flag_ttl = 4 * 3600; // 4 hours

export function checkAb() {
  return new Promise((resolve, reject) => {
    if (cookie.get(ab_flag_name)) {
      reject();
      return;
    }
    let script = document.createElement('script');
    script.src = '//vmnapi.net/vmap/js/showad.js';
    script.onload = () => {
      cookie.remove(ab_flag_name);
      resolve();
    };
    script.onerror = e => {
      if (!cookie.get(ab_flag_name)) {
        // cookie.set(ab_flag_name, 1, ab_flag_ttl);
      }
      reject(e);
    };
    document.head.append(script);
  });
}

export function checkExtension() {}

export function truncate(n, useWordBoundary) {
  if (this.length <= n) {
    return this;
  }
  var subString = this.substr(0, n - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString) + '&hellip;'
  );
}

export const cookie = {
  set: (name, value, ttl, path) => {
    var expires = new Date(Date.now() + ttl * 1000);
    document.cookie =
      name +
      '=' +
      value +
      ';expires=' +
      expires.toUTCString() +
      ';path=' +
      (path || '/');
  },

  get: name => {
    let value = new RegExp(
      '(?:^|; )' + encodeURIComponent(name) + '=([^;]*)',
    ).exec(document.cookie);
    return value ? value[1] : null;
  },

  remove: name => {
    cookie.set(name, '', -3600);
  },
};

export function validateEmail(email) {
  return (
    typeof email === 'string' &&
    email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
  );
}

export function subscribeToMaling(email_address) {
  return fetch('https://api.gaiamission.org/v1/mailing/2525e555a9', {
    method: 'POST',
    body: JSON.stringify({ email_address, status: 'subscribed' }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
}
