import React from 'react';
import ScrollableScene from 'components/ScrollableScene';

const NotFound = props => (
  <ScrollableScene className="notfound-page">
    <div className="row">
      <h1>This page cannot be found</h1>
    </div>
  </ScrollableScene>
);

export default NotFound;
