import YHPaginator from './YHPaginator';
const testTraffic = process.env.NODE_ENV === 'development';

const webSearchUrl = 'https://api.gaiamission.org/v1/websearch/gaia?on=0' + (testTraffic ? '&test=0':'');

const YPA_CONFIG_STR = JSON.stringify({
  ypaAdConfig: '0000013af',
  ypaAdTypeTag: '',
  ypaAdSlotInfo: [
    {
      ypaAdSlotId: 'TOP_AD',
      ypaAdDivId: 'ypaAdWrapper-TOP_AD',
      ypaAdWidth: '600',
      ypaAdHeight: '491',
      ypaSlotOptions: {
        TemplateOptions: {
          DeskTop: {
            AdUnit: {
              cssLink: '//gaiamission.org/css/ypa/common.css'
            }
          }
        }
      }
    },
    {
      ypaAdSlotId: 'BOTTOM_AD',
      ypaAdDivId: 'ypaAdWrapper-BOTTOM_AD',
      ypaAdWidth: '600',
      ypaAdHeight: '491',
      ypaSlotOptions: {
        TemplateOptions: {
          DeskTop: {
            AdUnit: {
              cssLink: '//gaiamission.org/css/ypa/common.css'
            }
          }
        }
      }
    },
    {
      ypaAdSlotId: 'FEATURED_AD',
      ypaAdDivId: 'ypaAdWrapper-FEATURED_AD',
      ypaAdWidth: '600',
      ypaAdHeight: '189',
      ypaSlotOptions: {
        TemplateOptions: {
          DeskTop: {
            AdUnit: {
              cssLink: '//gaiamission.org/css/ypa/common.css'
            }
          }
        }
      }
    },
    {
      ypaAdSlotId: 'ALGO_MIDDLE',
      ypaAdDivId: 'ypaAdWrapper-ALGO_MIDDLE',
      ypaAdWidth: '600',
      ypaAdHeight: '685',
      ypaSlotOptions: {
        TemplateOptions: {
          DeskTop: {
            AdUnit: {
              cssLink: '//gaiamission.org/css/ypa/common.css'
            }
          }
        }
      }
    }
  ]
});

export function getXmlSearchResults(keyword, typeTag = '', wrp = 0, xargs = null) {
  return fetch(webSearchUrl + '&q=' + encodeURIComponent(keyword) + '&tt=' + encodeURIComponent(typeTag + '__xml'))
    .then(res => res.json())
    .then(data => {
      let paginator = new YHPaginator(data.result, wrp, xargs);
      return {
        organic: paginator.getCurrentPageResults(),
        topBidded: paginator.popSponsoredResults(3),
        bottomBidded: paginator.popSponsoredResults(3),
        totalHits: paginator.getTotalHits(),
        navElements: paginator.getNavigationElements(),
      };
    });
}

export function getYPASearchResults(keyword, typeTag = '', currentPage = 0, noAlgoCallback = null) {
  const YPA_CONFIG = JSON.parse(YPA_CONFIG_STR);
  if(testTraffic) YPA_CONFIG.ypaAdTrafficType = 'test';
  YPA_CONFIG.ypaPubParams = { query: keyword };
  YPA_CONFIG.ypaAdTypeTag = typeTag + '__ypa';
  YPA_CONFIG.ypaPageCount = currentPage;
  if (noAlgoCallback) {
    YPA_CONFIG.ypaAdSlotInfo[3].ypaOnNoAd = noAlgoCallback;
  }

  if (window.ypaAds) {
    window.ypaAds.insertMultiAd(YPA_CONFIG);
  }
}

export function getYPANavElements(current = 0, limit = 10) {
  const nav = Array.from(new Array(limit), (val, index) => {
    return {
      caption: index + 1,
      offset: (current !== index) ? index : null,
      params: 'wrp=' + index
    }
  });

  if((current > 0)){
    nav.unshift({
      caption: 'Previous',
      offset: current-1,
      params: 'wrp=' + (current-1)
    });
  }

  if((current < limit-1)){
    nav.push({
      caption: 'Next',
      offset: current+1,
      params: 'wrp=' + (current+1)
    });
  }

  return nav;
}

export async function detectAb() {
}