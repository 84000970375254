import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NavHashLink } from 'react-router-hash-link';
import shortid from 'shortid';
import MissionSelectorContainer from '../MissionSelector/container';
import './style.css';

class HeaderNav extends Component {
  handleCloseClick(e) {
    e.preventDefault();
    this.closeMenu();
  }

  handleOpenClick(e) {
    e.preventDefault();
    this.openMenu();
  }

  handleBodyClick(e) {
    if (!this.excludedTargets.includes(e.target)) {
      this.closeMenu();
    }
  }

  closeMenu() {
    document.body.classList.remove('menu-is-open');
  }

  openMenu() {
    document.body.classList.add('menu-is-open');
  }

  handleScroll(e) {
    let scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollTop > 150) {
      document.getElementById('headr-menu-toggle').classList.add('opaque');
    } else {
      document.getElementById('headr-menu-toggle').classList.remove('opaque');
    }
  }

  componentDidMount() {
    this.excludedTargets = Array.from(
      document.querySelectorAll(
        '.header-nav, .header-nav__content, .header-menu-toggle, .header-menu-toggle span, .theme-selector',
      ),
    );
    document.body.addEventListener('click', e => {
      this.handleBodyClick(e);
    });
    window.addEventListener('scroll', e => this.handleScroll(e));
  }

  componentWillUnmount() { }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>
        <nav className="header-nav">
          <a
            href="#0"
            onClick={e => this.handleCloseClick(e)}
            className="header-nav__close"
            title={formatMessage({id: 'app.menu.close'})}
          >
            <span>Close</span>
          </a>
          <div className="header-nav__content">
            <h3>GAIA MISSION</h3>
            <ul className="header-nav__list">
              {this.props.menuLinks.map(menuLink => (
                <li key={shortid.generate()}>
                  <NavHashLink smooth to={menuLink.path}>
                    {formatMessage({id: menuLink.title})}
                  </NavHashLink>
                </li>
              ))}
            </ul>
          </div>
          <MissionSelectorContainer />
          <div className="header-nav-footer">
            <a target="_blank" href="/privacy">
              <FormattedMessage id="app.footer.privacy"></FormattedMessage>
            </a>{' '}
            -{' '}
            <a target="_blank" href="/eula">
              <FormattedMessage id="app.footer.eula"></FormattedMessage>
            </a>{' '}
            -{' '}
            <a target="_blank" href="/financials">
              <FormattedMessage id="app.footer.financials"></FormattedMessage>
            </a>
          </div>
        </nav>
        <a
          id="headr-menu-toggle"
          className="header-menu-toggle"
          onClick={e => this.handleOpenClick(e)}
          href="#0"
        >
          <span className="header-menu-icon"></span>
        </a>
      </React.Fragment>
    );
  }
}

export default injectIntl(HeaderNav);
