import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import AppThemeContainer from 'views/AppTheme';
import Landing from 'views/Landing';
import SearchResults from 'views/SearchResults';
import NotFound from 'views/NotFound';
import PrivacyPage from 'views/Privacy';
import EulaPage from 'views/Eula';
import UninstallPage from 'views/Extension/Uninstall';
import CongratulationPage from 'views/Extension/Congratulation';
import Install from 'views/Extension/Install';
import FinancialsPage from 'views/Financials';
import AdBlockHelp from 'views/Help/Adblock';
import store from 'redux/store';
import ReactGA from 'react-ga';
import locale_en from './locales/en.json';
import locale_fr from './locales/fr.json';

const locales = {
  'en': locale_en,
  'fr': locale_fr
};

let language = navigator.language.split(/[-_]/)[0];
if (!locales[language]) {
  language = 'en';
}

language='en';

ReactGA.initialize('UA-153482048-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  componentDidMount() {
    if (window.location.hash) {
      const id = window.location.hash.replace("#", "");
      const element = document.getElementById(id);
      element.scrollIntoView();
    }
  }
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <IntlProvider locale={language} messages={locales[language]}>
            <AppThemeContainer>
              <Switch>
                <Route exact path="/" component={Landing} />
                <Route path="/search" component={SearchResults} />
                <Route path="/privacy" component={PrivacyPage} />
                <Route path="/eula" component={EulaPage} />
                <Route path="/financials" component={FinancialsPage} />
                <Route path="/ext/uninstall" component={UninstallPage} />
                <Route path="/uninstall" component={UninstallPage} />
                <Route path="/ext/congratulation" component={CongratulationPage} />
                <Route path="/ext/install" component={Install} />
                <Route path="/help/ab" component={AdBlockHelp} />
                <Route component={NotFound} />
              </Switch>
            </AppThemeContainer>
          </IntlProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
