import React, { Component } from 'react';
import { cookie } from 'common/utils';
import { getMissionDataById } from 'common/MissionAPI';

import './style.css';

const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

const offerFlag = 'ext_off';
const offerTTL = 12 * 3600;

class ChromeBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closed: true,
      installUrl: getMissionDataById(props.mission.id).ext.url
    };
  }

  componentDidMount() {
    if (isChrome && !cookie.get(offerFlag)) {
      setTimeout(() => {
        if (!document.getElementById('gaiamissionextension')) {
          this.setState({ closed: false });
          cookie.set(offerFlag, 1, offerTTL);
        }
      }, 1000);
    }
  }

  onClose = e => {
    e.preventDefault();
    this.setState({ closed: true });
  };

  installClick = () => {
    this.setState({ closed: true });
    window.open(
      this.state.installUrl,
      '_blank',
    );
  };

  render() {
    return (
      <div
        className="chrome-banner"
        style={{ display: this.state.closed ? 'none' : 'block' }}
      >
        <a className="chrome-banner-close" href="#0" onClick={this.onClose}>
          &#x00D7;
        </a>
        <div className="chrome-banner-content">
          <div className="chrome-banner-title">
            {this.props.mission.ext.title}{' '}
            <span className="chrome-banner-mission">
              {this.props.mission.title}
            </span>
          </div>
          <div className="chrome-banner-text">
            With{' '}
            <span className="chrome-banner-textlogo">
              <span className="chrome-banner-mission">GAIA</span>MISSION
            </span>{' '}
            extension, your searches generate donations to{' '}
            {this.props.mission.ext.objective}.
          </div>
          <button onClick={this.installClick}>
            <span
              className="fa fa-chrome fa-lg"
              style={{ verticalAlign: 'middle', marginRight: '1rem' }}
            ></span>
            Add to Chrome
          </button>
        </div>
      </div>
    );
  }
}

export default ChromeBanner;
