import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import './style.css';

const Footer = props => (
  <div className="footer">
    <span>
      <FormattedHTMLMessage id='app.footer.copyright' values={{ year: new Date().getFullYear() }}></FormattedHTMLMessage> |
      Gaiamission.org |{' '}
      <a target="_blank" href="/privacy">
      <FormattedHTMLMessage id='app.footer.privacy'></FormattedHTMLMessage>
      </a>{' '}
      |{' '}
      <a target="_blank" href="/eula">
      <FormattedHTMLMessage id='app.footer.eula'></FormattedHTMLMessage>
      </a>{' '}
      | <a href="/#contact"><FormattedHTMLMessage id='app.footer.contact'></FormattedHTMLMessage></a>
    </span>
  </div>
);

export default Footer;
