import React from 'react';
import classnames from 'classnames';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ScrollableScene from 'components/ScrollableScene';
import HeaderLogo from 'components/HeaderLogo';
import Footer from 'components/Footer';
import { injectThemeHelpers } from 'components/MissionTheme';
import './style.css';

const FinancialsPage = props => (
  <div className="financial-page">
    <div className="header-section">
      <HeaderLogo></HeaderLogo>
    </div>
    <ScrollableScene className="privacy-page">
      <div className="row section-header">
        <div className="col-full">
          <h1 className="display-1">
            When you search with Gaia Mission, 100% of our profits go to support
            environmental causes
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-full">
          <VerticalTimeline animate={false}>
            <VerticalTimelineElement
              contentStyle={{ background: 'var(--main-theme-color)', color: '#fff' }}
              contentArrowStyle={{borderRight: '7px solid  var(--main-theme-color)'}}
              iconStyle={{ background: 'var(--main-theme-color)', color: '#fff', textAlign: 'center'}}
              icon={<i className="las la-search la-2x gaia-tl-icon"></i>}
            >
              <h4 className="vertical-timeline-element-subtitle gaia-tl-subtitle">
                When you search the web with Gaia Mission, as with other search
                engines, search ads are displayed on the search result page.
              </h4>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              contentStyle={{ background: 'var(--main-theme-color)', color: '#fff' }}
              contentArrowStyle={{borderRight: '7px solid  var(--main-theme-color)'}}
              iconStyle={{ background: 'var(--main-theme-color)', color: '#fff', textAlign: 'center'}}
              icon={<i className="las la-donate la-2x gaia-tl-icon"></i>}
            >
              <h4 className="vertical-timeline-element-subtitle gaia-tl-subtitle">
                Advertising revenue is generated when you click on the search
                ads, we share the revenue with our advertising partners, and we
                receive our part of the revenue.
              </h4>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              contentStyle={{ background: 'var(--main-theme-color)', color: '#fff' }}
              contentArrowStyle={{borderRight: '7px solid  var(--main-theme-color)'}}
              iconStyle={{ background: 'var(--main-theme-color)', color: '#fff', textAlign: 'center'}}
              icon={<i className={classnames(props.selectedMission.icon,'la-2x' ,'gaia-tl-icon')}></i>}
            >
              <h4 className="vertical-timeline-element-subtitle gaia-tl-subtitle">
                After deducting all operational costs and advertising expenses,
                the entire profit is distributed among our partners who then use
                the money to fund their projects.{' '}
              </h4>
            </VerticalTimelineElement>
          </VerticalTimeline>
          <p className="lead"  style={{marginTop: '4rem'}}>
            We value your support to our causes. That’s why it is important for
            us to be transparent with you and be accountable for what we do. Our
            monthly financial reports show exactly how we used the money
            generated from your searches.
          </p>
          <p className="lead">
            Please note that we are still a new non-profit organization, our
            first financial report will be available by the end of February 2020.
          </p>
        </div>
      </div>
    </ScrollableScene>
    <Footer></Footer>
  </div>
);

export default injectThemeHelpers(FinancialsPage);