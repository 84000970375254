import React from 'react';

const GaiaLogo = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 256"
    className={props.styleName}
    width={props.width}
    height={props.height}
  >
    <title>Gaia Logo</title>
    <g fill={props.color}>
      <path d="M179.7,52.4C169,23.8,152.6,3.9,134.3,0.1c-0.2,0-0.5,0-0.7,0l0.5,52.8L179.7,52.4z" />
      <path
        d="M57.9,67.1l-42.5,0.4c-0.1,0-0.2,0-0.3,0.1C9.4,78.2,5.1,89.8,2.6,102c16.7,0.3,33.2,3.2,49,8.6
            C52.3,96,54.5,81.4,57.9,67.1z"
      />
      <path d="M120.4,53L120,0.6c-17.8,4.6-33.5,24.6-43.4,52.9L120.4,53z" />
      <path d="M61.9,53.2c6.2-19,14.8-35,25.1-46.6c-25.8,8.7-47.9,25.5-63.4,47.2l37.9-0.3C61.6,53.5,61.8,53.4,61.9,53.2z" />
      <path
        d="M194.8,52.3l32.5-0.3c1.6,0,2.4-0.7,2.3-1.8c-15.3-19.9-36.3-35.2-60.5-43.5C179.4,18,188,33.5,194.4,52
            C194.5,52.2,194.6,52.3,194.8,52.3z"
      />
      <path
        d="M205.7,109.8c15.4-5,31.5-7.6,47.6-7.8c-2.7-13-7.4-25.3-13.7-36.6c-0.1,0-0.2-0.1-0.4-0.1l-40.7,0.4
            C202.3,80.1,204.7,94.9,205.7,109.8z"
      />
      <path d="M135.5,156.3c7.2-8.3,15.2-15.8,24-22.4l-24.6,0.3l0.2,22.6L135.5,156.3z" />
      <path d="M96,134.5c7.5,5.6,14.4,11.9,20.7,18.7l4.7,5.2l-0.3-24.1L96,134.5z" />
      <path d="M65,115.9c3.6,1.6,7.1,3.4,10.5,5.2l45.6-0.4l-0.5-54.3L72.2,67C68,83,65.5,99.4,65,115.9z" />
      <path d="M192.5,114.8c-0.9-16.5-3.7-32.9-8.2-48.9l-50.1,0.5l0.5,54.2l46.5-0.4C184.8,118.2,188.6,116.4,192.5,114.8z" />
      <path
        d="M255.4,115.6h-2.9c-40.3,0-78.7,17.4-105.1,47.8L128,185.8l-22.9-25.1c-26.4-28.8-63.7-45.2-102.9-45.1H0.6
            c-0.4,4.1-0.6,8.2-0.6,12.3c0,70.7,57.3,128,128,128s128-57.3,128-128C256,123.8,255.8,119.7,255.4,115.6z"
      />
    </g>
  </svg>
);

export default GaiaLogo;
