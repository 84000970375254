import { combineReducers } from 'redux';

import missionReducer from 'views/Landing/pages/Header/redux/reducer';

export default combineReducers({
  mission: missionReducer,
});

export const getSelectedMission = state => {
  return state.mission.missionsList.find(mission => {
    return mission.id === state.mission.selectedMissionId;
  });
};
