import React from 'react';
import { FormattedNumber } from 'react-intl';

import './style.css';

const Listingheader = props => (
  <div className="listing-header">
    {
      props.bidded ?
      <span>Ads related to <b>{props.keyword}</b></span>
        :
        <span>Search results for <b>{props.keyword}</b> {isNaN(props.hits) ? '' : <span>(<FormattedNumber value={props.hits} /> results)</span>}</span>
    }
  </div>
);

export default Listingheader;
