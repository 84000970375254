import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ScrollableScene from 'components/ScrollableScene';

class About extends Component {
  render() {
    return (
      <ScrollableScene id="about">
        <div className="row section-header" data-aos="fade-up">
          <div className="col-full">
            <h3 className="subhead"><FormattedMessage id='app.menu.who-we-are'></FormattedMessage></h3>
            <h1 className="display-1">
              <FormattedMessage id='app.section.who-we-are.header1'></FormattedMessage>
            </h1>
          </div>
        </div>
        <div className="row" data-aos="fade-up">
          <div className="col-full">
            <p className="lead">
            <FormattedMessage id='app.section.who-we-are.paragraph1'></FormattedMessage>
            </p>
          </div>
        </div>
      </ScrollableScene>
    );
  }
}

export default About;
