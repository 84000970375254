import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { loadState, saveState } from './localStorage';
import throttle from 'lodash/throttle';
import reducers from './reducers';

const persistedState = loadState();
if (
  persistedState &&
  persistedState.mission &&
  persistedState.mission.missionsList
) {
  delete persistedState.mission.missionsList;
}

const store = createStore(reducers, persistedState, applyMiddleware(logger));

store.subscribe(
  throttle(() => {
    saveState({
      mission: {
        selectedMissionId: store.getState().mission.selectedMissionId,
      },
    });
  }, 1000),
);

export default store;
