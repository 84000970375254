import React from 'react';
import classnames from 'classnames';

import './style.css';

const MissionHeader = props => (
  <div className={classnames('mission-caption', props.className)}>
    <span className="logo-text-2">MISSION</span>&nbsp;
    <span className="logo-text-1">{props.missionTitle}</span>
  </div>
);

export default MissionHeader;
