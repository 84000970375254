import React, { Component } from 'react';
import ScrollableScene from 'components/ScrollableScene';
import SearchBox from 'components/SearchBox';
import HomeScroll from 'components/HomeScroll';
import MissionHeader from 'components/MissionHeader';
import MissionCounter from 'components/MissionCounter';
import HomeSocial from './components/HomeSocial';
import ChromeBanner from 'components/ChromeBanner';


import './style.css';

class Home extends Component {
  render() {
    return (
      <ScrollableScene
        id="home"
        className="s-home parallax"
        style={{
          backgroundImage: `url('${this.props.selectedMission.bg_image}')`,
        }}
      >
        <ChromeBanner mission={this.props.selectedMission}/>
        <div className="row">
          <MissionHeader
            className="home-mission-header"
            missionTitle={this.props.selectedMission.title}
          />
          <SearchBox typeTag={this.props.selectedMission.id}></SearchBox>
          <MissionCounter {...this.props.selectedMission.counter} />
        </div>
        <HomeSocial />
        <HomeScroll topTarget={'/#home'} bottomTarget={'/#about'} />
      </ScrollableScene>
    );
  }
}

export default Home;
