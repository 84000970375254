import { connect } from 'react-redux';
import { missionSelect } from '../../redux/actions';
import MissionSelector from './';
import './style.css';

const mapStateToProps = store => store.mission;
const mapDispatchToProps = { missionSelect };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MissionSelector);
