import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

const PageNavigator = props => (
  <ul className="listing-pagination">
    {props.navElements && props.navElements.map((navElement, index) => {
      if (navElement.offset === null) {
        return (
          <li key={index} className="current-index">
            {navElement.caption}
          </li>
        );
      }
      return (
        <Link
          key={index}
          to={{
            pathname: '/search',
            search:
              `q=${encodeURIComponent(props.keyword)}&` + navElement.params,
          }}
        >
          <li>{navElement.caption}</li>
        </Link>
      );
    })}
  </ul>
);

export default PageNavigator;
