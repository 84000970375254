import React, { Component } from 'react';
import './style.css';
import GaiaLogo from 'components/GaiaLogo';

class HeaderLogo extends Component {
  render() {
    return (
      <div className="header-logo">
        <a className="site-logo" href="/">
          <GaiaLogo styleName="svg-logo" width={38} height={38} />
          <span className="logo-text-1">GAIA</span>
          <span className="logo-text-2">MISSION</span>
        </a>
      </div>
    );
  }
}

export default HeaderLogo;
