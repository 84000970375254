import React, { Component } from 'react';
import { injectThemeHelpers } from 'components/MissionTheme';
import queryString from 'query-string';
import { cookie } from 'common/utils';
import { getMissionDataById } from 'common/MissionAPI';

//TODO: move tracking login to utils
const CONVERSION_TRACKER_NAME = 'agcid';
const CONVERSION_TRACKER_TTL = 7 * 24 * 3600;

class Install extends Component {
  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);
    let trackerParam = queryParams[CONVERSION_TRACKER_NAME] || null;
    let mid = queryParams['mid'] || null;
    if (trackerParam) {
      cookie.set(CONVERSION_TRACKER_NAME, trackerParam, CONVERSION_TRACKER_TTL);
    }

    const selectedMission = getMissionDataById(mid);
    //TODO: URGETNT!! review mission selection by ulr param
    if (selectedMission) {
      window.location.replace(selectedMission.ext.url);
    }
  }

  render() {
    return <div></div>;
  }
}

export default injectThemeHelpers(Install);
