import React, { Component } from 'react';
import ScrollableScene from 'components/ScrollableScene';
import HeaderLogo from 'components/HeaderLogo';
import { cookie } from 'common/utils';
import { injectThemeHelpers } from 'components/MissionTheme';

import './style.css';

//TODO: move tracking logic to utils
const CONVERSION_TRACKER_NAME = 'agcid';

class Congratulation extends Component {
  componentDidMount() {
    setTimeout(() => {
      const conversionId = cookie.get(CONVERSION_TRACKER_NAME);
      const extDiv = document.getElementById('gaiamissionextension');
      const extInstalled =
        extDiv &&
        extDiv.getAttribute('extensionid') ===
          this.props.selectedMission.ext.id;
      if (extInstalled && conversionId) {
        fetch('https://api.gaiamission.org/v1/conversion/s9nswmnk', {
          method: 'POST',
          body: 'agcid=' + encodeURIComponent(conversionId),
          headers: { 'Content-type': 'application/x-www-form-urlencoded' }
        });
        cookie.remove(CONVERSION_TRACKER_NAME);
      }
    }, 1000);
  }

  render() {
    return (
      <ScrollableScene
        id="home"
        className="s-home parallax"
        style={{
          backgroundImage: `url('${this.props.selectedMission.bg_image}')`,
        }}
      >
        <div className="header-section">
          <HeaderLogo></HeaderLogo>
        </div>
        <div className="row section-header">
          <div className="col-full">
            <h1 className="display-1" style={{ fontSize: '3.6rem' }}>
              Thank you for installing our Chrome Extension
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-full">
            {/*
            <p className="lead">
              With GAIA MISSION extension, your online searches generate
              donations to help the environement.
            </p>
            */}
          </div>
          <div style={{ textAlign: 'center' }}>
            <img
              src={
                '/images/chrome/install/browser-' +
                this.props.selectedMission.id +
                '.png'
              }
              alt=""
            ></img>
          </div>
        </div>
      </ScrollableScene>
    );
  }
}

export default injectThemeHelpers(Congratulation);
