import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSelectedMission } from 'redux/reducers';

class AppTheme extends Component {
  componentDidMount() {
    document.documentElement.style.setProperty(
      '--main-theme-color',
      this.props.selectedMission.color,
    );
  }

  componentDidUpdate() {
    document.documentElement.style.setProperty(
      '--main-theme-color',
      this.props.selectedMission.color,
    );
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

const mapStateToProps = state => ({
  selectedMission: getSelectedMission(state),
});

const AppThemeContainer = connect(mapStateToProps)(AppTheme);

export default AppThemeContainer;
