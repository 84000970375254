import React, { Component } from 'react';
import classnames from 'classnames';
import { NavHashLink } from 'react-router-hash-link';
import './style.css';

class HomeScroll extends Component {
  constructor(props) {
    super(props);
    this.state = { target: props.bottomTarget, reverseClass: '' };
  }

  handleScroll(e) {
    let scrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (scrollTop > 150) {
      this.setState({
        target: this.props.topTarget,
        reverseClass: 'home-scroll__icon__reverse',
      });
    } else {
      this.setState({ target: this.props.bottomTarget, reverseClass: '' });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', e => this.handleScroll(e));
  }

  componentWillUnmount() {}

  render() {
    return (
      <NavHashLink smooth to={this.state.target} className="home-scroll">
        <span
          className={classnames('home-scroll__icon', this.state.reverseClass)}
        ></span>
      </NavHashLink>
    );
  }
}

export default HomeScroll;
