import React, { Component } from 'react';
import HeaderLogo from 'components/HeaderLogo';
import HeaderNav from './components/HeaderNav';
import './style.css';

const menuLinks = [
  {
    path: '/#home',
    title: 'app.menu.home',
  },
  {
    path: '/#howitworks',
    title: 'app.menu.how-it-works',
  },
  {
    path: '/#about',
    title: 'app.menu.who-we-are',
  },
  {
    path: '/#mission',
    title: 'app.menu.our-mission',
  },
  {
    path: '/#partner',
    title: 'app.menu.our-partner',
  },
  {
    path: '/#work',
    title: 'app.menu.our-impact',
  },
  {
    path: '/#contact',
    title: 'app.menu.contact-us',
  },
];

class Header extends Component {
  render() {
    return (
      <div className="header-section">
        <HeaderLogo />
        <HeaderNav menuLinks={menuLinks} />
      </div>
    );
  }
}

export default Header;
