import React from 'react';
import { truncate } from 'common/utils';

import './style.css';

const ListingDescription = props => (
  <div
    className="sr-snippet"
    dangerouslySetInnerHTML={{ __html: truncate.apply(props.text, [320, true]) }}
  ></div>
);

export default ListingDescription;
