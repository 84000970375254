import React from 'react';

import Listingheader from '../Listingheader';
import ResultLine from '../ResultLine';

import './style.css';

const BiddedResults = props => (
  <div>
    <Listingheader keyword={props.keyword} bidded={true} />
    <div className="sr-web-result">
      {props.listing.map((entry, index) => {
        return (
          <ResultLine
            key={index}
            title={entry.title}
            siteHost={entry.siteHost}
            description={entry.description}
            clickUrl={entry.clickUrl}
            bidded={true}
            siteLinks={entry.siteLinks}
          />
        );
      })}
    </div>
  </div>
);

export default BiddedResults;
