import React, { Component } from 'react';
import queryString from 'query-string';

import SearchResultPage from './components/SearchResultPage';

class SearchResults extends Component {
  constructor(props) {
    super(props);
    let queryParams = queryString.parse(props.location.search);
    this.state = {
      keyword: queryParams['q'],
      wrp: queryParams['wrp'] || 0,
      xargs: queryParams['xargs'],
      typeTag: queryParams['tt']
    };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.queryParams = queryString.parse(location.search);
      this.setState({
        keyword: this.queryParams['q'],
        wrp: this.queryParams['wrp'],
        xargs: this.queryParams['xargs'],
        typeTag: this.queryParams['tt'],
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return <SearchResultPage {...this.state} />;
  }
}

export default SearchResults;
