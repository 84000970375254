import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import ScrollableScene from 'components/ScrollableScene';
import { validateEmail, subscribeToMaling } from 'common/utils';
import './style.css';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '', error: false, submitted: false };
  }

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
    if (!validateEmail(this.state.email)) {
      this.setState({ error: true });
      return;
    }
    this.setState({ error: false });
    this.setState({ submitted: false });
    subscribeToMaling(this.state.email).finally(() => {
      this.setState({ submitted: true });
      this.setState({ email: '' });
    });
  };

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <ScrollableScene id="contact" className="s-contact">
        <div className="row section-header" data-aos="fade-up">
          <div className="col-full">
            <h3 className="subhead">
              <FormattedMessage id="app.menu.contact-us"></FormattedMessage>
            </h3>
            <h1 className="display-1 display-1--light">
              <FormattedMessage id="app.section.contact.header1"></FormattedMessage>
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-full contact-main" data-aos="fade-up">
            <p>
              <a href="mailto:#0" className="contact-email">
                info@gaiamission.org
              </a>
              <span className="contact-number">+1 (450) 476 0374</span>
            </p>
          </div>
        </div>

        <div className="row">
          <div
            className="col-five tab-full contact-secondary"
            data-aos="fade-up"
          >
            <h3 className="subhead subhead--light">
              <FormattedMessage id="app.section.contact.where-to-find"></FormattedMessage>
            </h3>

            <p className="contact-address">
              6200 Taschereau, suite 301
              <br />
              Brossard, QC
              <br />
              J4W 3J8 CANADA
            </p>
          </div>

          <div
            className="col-five tab-full contact-secondary"
            data-aos="fade-up"
          >
            <h3 className="subhead subhead--light">
              <FormattedMessage id="app.section.contact.follow-us"></FormattedMessage>
            </h3>

            <ul className="contact-social">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/TheGaiaMission"
                >
                  <i className="lab la-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/GaiaMission"
                >
                  <i className="lab la-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/gaiamission__/"
                >
                  <i className="lab la-instagram"></i>
                </a>
              </li>
            </ul>

            <div className="contact-subscribe">
              <form
                id="mc-form"
                className="group mc-form"
                noValidate={true}
                onSubmit={this.onSubmit}
              >
                <input
                  type="email"
                  name="email"
                  className="email"
                  id="mc-email"
                  placeholder={formatMessage({
                    id: 'app.general.email-address',
                  })}
                  required={true}
                  onChange={this.handleEmailChange}
                  value={this.state.email}
                />
                <input
                  type="submit"
                  name="subscribe"
                  value={formatMessage({ id: 'app.general.subscribe' })}
                />
                <label
                  htmlFor="mc-email"
                  className={classnames('subscribe-message', {
                    'error-message': this.state.error,
                  })}
                  style={{
                    visibility: this.state.submitted ? 'visible' : 'hidden',
                  }}
                >
                  <span>
                    {this.state.error
                      ? '* Please submit a valid email'
                      : 'Thank you for your subscription!'}
                  </span>
                </label>
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-full cl-copyright">
            <span>
              <FormattedHTMLMessage
                id="app.footer.copyright"
                values={{ year: new Date().getFullYear() }}
              ></FormattedHTMLMessage>{' '}
              | Gaiamission.org
            </span>
          </div>
        </div>
      </ScrollableScene>
    );
  }
}

export default injectIntl(Contact);
