import React, { Component } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './pages/Header';
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks';
import About from './pages/About';
import Work from './pages/Work';
import Mission from './pages/Mission';
import Partner from './pages/Partner';
import Contact from './pages/Contact';
import { injectThemeHelpers } from 'components/MissionTheme'

class Landing extends Component {
  componentDidMount() {
    AOS.init({
      offset: 100,
      duration: 400,
      easing: 'ease-in-sine',
      delay: 150,
      once: true,
      disable: 'mobile',
    });
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Home selectedMission={this.props.selectedMission} />
        <HowItWorks selectedMission={this.props.selectedMission} />
        <About />
        <Mission />
        <Partner />
        <Work />
        <Contact />
      </React.Fragment>
    );
  }
}

export default injectThemeHelpers(Landing);
