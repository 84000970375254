import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const MasonryBrick = props => (
  <div className="masonry__brick" data-aos="fade-up">
    <div onClick={props.onItemClick} className="item-folio">
      <div className="item-folio__thumb">
        <a
          href={props.image}
          className="thumb-link"
          title={props.title}
          data-size="1200x800"
        >
          <img src={props.thumb} srcSet={props.thumb + '  1x'} alt="" />
        </a>
      </div>

      <div className="item-folio__text">
        <h3 className="item-folio__title">{props.title}</h3>
        <p className="item-folio__cat">{props.text}</p>
      </div>

      <a
        href={props.link}
        className="item-folio__project-link"
      >
        <FormattedMessage id='app.general.project-link'></FormattedMessage>
      </a>

      <div className="item-folio__caption">
        <p>{props.text}</p>
      </div>
    </div>
  </div>
);

export default injectIntl(MasonryBrick);
