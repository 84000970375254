import React, { Component } from 'react';
import './style.css';

class HomeSocial extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <ul className="home-social">
        {/*<li className="home-social-title">Follow Us</li>*/}
        <li>
          <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/TheGaiaMission">
          <i className="lab la-facebook-f"></i>
          </a>
        </li>
        <li>
          <a target='_blank' rel="noopener noreferrer" href="https://twitter.com/GaiaMission">
            <i className="lab la-twitter" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/gaiamission__/">
            <i className="lab la-instagram" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    );
  }
}

export default HomeSocial;
