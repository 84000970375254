import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import Autosuggest from 'react-autosuggest';
import fetchJsonp from 'fetch-jsonp';

import './style.css';

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.initialValue || '', suggestions: [] };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSubmit = e => {
    if (!this.state.value) {
      e.preventDefault();
      return false;
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.initSuggestRequest(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderSuggestion = (suggestion, { query }) => {
    return <div dangerouslySetInnerHTML={{ __html: suggestion.label }}></div>;
  };

  getSuggestionValue = suggestion => suggestion.value;

  shouldRenderSuggestions = (value) => {
    if(this.props.initialValue !== value || this._suggestionFlagCleared){
      return true;
    }
    this._suggestionFlagCleared = true;
    return false;
  }

  initSuggestRequest = value => {
    this.yhAutoSuggest(value, 10)
      .then(result => {
        this.setState({ suggestions: result });
      })
      .catch(e => {
        this.setState({ suggestions: [] });
      });
  };

  async yhAutoSuggest(kwd, limit) {
    if (kwd) {
      const response = await fetchJsonp(
        `https://search.yahoo.com/sugg/gossip/gossip-us-sayt?output=yjsonp&l=1&nresults=${limit}&command=${encodeURIComponent(
          kwd,
        )}&_=${new Date().getTime()}`,
      );
      const data = await response.json();
      return data.r.map((val, index) => {
        return {
          value: val[0],
          label: this.formatResult(kwd, val[0]),
          key: index,
        };
      });
    }
    return Promise.reject();
  }

  formatResult(kwd, suggestion) {
    return suggestion.replace(
      new RegExp(`(${this.escapeRegExChars(kwd)})`, 'gi'),
      "<span class='react-autosuggest__suggestion-match'>$1</span>",
    );
  }

  escapeRegExChars(value) {
    return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
  }

  render() {
    const { value, suggestions } = this.state;
    const { formatMessage } = this.props.intl;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: formatMessage({ id: 'app.search.palceholder' }),
      value,
      name: 'q',
      className: 'form-control',
      autoComplete: 'off',
      autoFocus: true,
      tabIndex: '0',
      onChange: this.onChange,
    };

    return (
      <div className={classnames('search-form-wrapper', this.props.className)}>
        <form
          id="search-form"
          className="search-form"
          action="/search/"
          method="get"
          onSubmit={e => this.onSubmit(e)}
          ref={el => (this._form = el)}
        >
          <div className="form-group">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              shouldRenderSuggestions={this.shouldRenderSuggestions}
              inputProps={inputProps}
            />
            <input type="hidden" name="tt" value={this.props.typeTag} />
          </div>
          <button type="submit">
            <i className="las la-search la-flip-horizontal"></i>
          </button>
        </form>
      </div>
    );
  }
}

export default injectIntl(SearchBox);
