import React from 'react';
import classnames from 'classnames';
import './style.css';

const ScrollableScene = props => (
  <section {...props} className={classnames('section', props.className)}>
    {props.children}
  </section>
);

export default ScrollableScene;
