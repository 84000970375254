import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Masonry from 'react-masonry-component';
import { PhotoSwipe } from 'react-photoswipe';
import shortid from 'shortid';
import ScrollableScene from 'components/ScrollableScene';
import MasonryBrick from './components/MasonryBrick';

import './style.css';
import 'react-photoswipe/lib/photoswipe.css';

const portfolio = [
  {
    title: 'Under Water Cleaning',
    text: "Dive Against Debris®, empowers scuba divers to remove marine debris from the ocean and report data on the types, quantities and locations of materials collected.",
    link: 'https://www.projectaware.org/diveagainstdebrismap',
    image: 'https://gaiamissioncdn.org/images/portfolio/gallery/ocean.jpg',
    thumb: 'https://gaiamissioncdn.org/images/portfolio/ocean.jpg',
  },
  {
    title: 'Replanting our Forests',
    text: 'Critical efforts to revitalize forests around the globe are underway to ensure that they live on for future generations, and you can support this important work.',
    link: 'https://www.arborday.org/programs/replanting',
    image: 'https://gaiamissioncdn.org/images/portfolio/gallery/tree.jpg',
    thumb: 'https://gaiamissioncdn.org/images/portfolio/tree.jpg',
  },
  {
    title: "Sponsor one of SAFE's animals",
    text: 'Students sitting in classrooms are tomorrow’s leaders. Farm Sanctuary is educating young hearts and minds about our modern food system. The natural sense of compassion that many youth possess for animals, combined with critical thinking skills and knowledge of our food system, is a powerful force for positive change.',
    link: 'https://www.farmsanctuary.org/humane-education/',
    image: 'https://gaiamissioncdn.org/images/portfolio/gallery/animals.jpg',
    thumb: 'https://gaiamissioncdn.org/images/portfolio/animals.jpg',
  },
  {
    title: 'Securing a Critical Wildlife Corridor in Kenya’s Northern Rangelands',
    text: 'Every $1 you donate today to support biodiversity in Kenya will be matched with $3 through our SAVES Challenge and another generous donor.',
    link: 'https://www.rainforesttrust.org/projects/securing-a-critical-wildlife-corridor-in-kenyas-northern-rangelands/',
    image: 'https://gaiamissioncdn.org/images/portfolio/gallery/wildlife.jpg',
    thumb: 'https://gaiamissioncdn.org/images/portfolio/wildlife.jpg',
  },
  {
    title: 'Saving Indigenous Lands in the Amazon',
    text:
      'Every $1 you donate today to save habitat in Peru will be matched with $1 through our SAVES Challenge. Your gift will have TWO times the impact!',
    link: 'https://www.rainforesttrust.org/projects/saving-indigenous-lands-in-the-amazon/',
    image: 'https://gaiamissioncdn.org/images/portfolio/gallery/forest.jpg',
    thumb: 'https://gaiamissioncdn.org/images/portfolio/forest.jpg',
  },
  /*{
    title: 'Inspiring others to follow an Adaptive Reefscapes approach',
    text: 'We piloted our Adaptive Reefscape approach in the Mesoamerican Region, and have expanded to Fiji, Indonesia, and Caribbean islands.',
    link: 'https://coral.org/adapt/',
    image: 'https://gaiamissioncdn.org/images/portfolio/gallery/coral.jpg',
    thumb: 'https://gaiamissioncdn.org/images/portfolio/coral.jpg',
  },
  */
];

const masonryOptions = {
  resize: true,
};

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoSwipeOpen: false,
    };
  }

  onItemClick(e, index) {
    e.preventDefault();
    window.open(portfolio[index].link, '_blank');
  }

  onClose() {
    this.setState({ photoSwipeOpen: false });
  }

  render() {
    return (
      <ScrollableScene id="work">
        <div className="row section-header" data-aos="fade-up">
          <div className="col-full">
            <h3 className="subhead"><FormattedMessage id='app.menu.our-impact'></FormattedMessage></h3>
            <h1 className="display-1">
              <FormattedMessage id="app.section.our-impact.header1"></FormattedMessage>
            </h1>
          </div>
        </div>
        <div className="row masonry-wrap">
          <Masonry className="masonry" options={masonryOptions}>
            {portfolio.map((brick, index) => {
              return (
                <MasonryBrick
                  key={shortid.generate()}
                  index={index}
                  onItemClick={e => this.onItemClick(e, index)}
                  {...brick}
                />
              );
            })}
          </Masonry>
        </div>
        {this.state.photoSwipeOpen && (
          <PhotoSwipe
            isOpen={this.state.photoSwipeOpen}
            items={portfolio.map(item => ({
              src: item.image,
              title: item.title,
            }))}
            onClose={() => this.onClose()}
          />
        )}
      </ScrollableScene>
    );
  }
}

export default Work;
