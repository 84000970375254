import React, { Component } from 'react';
import ScrollableScene from 'components/ScrollableScene';
import HeaderLogo from 'components/HeaderLogo';
import { injectThemeHelpers } from 'components/MissionTheme';

import './style.css';

class AdBlockHelp extends Component {
  render() {
    return (
      <ScrollableScene
        id="home"
      >
        <div className="header-section">
          <HeaderLogo></HeaderLogo>
        </div>
        <div className="row section-header">
          <div className="col-full">
            <h1 className="display-1" style={{ fontSize: '3.6rem' }}>
              How to make an exception in Ad Blocker for Gaia Mission
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-full">
            <p className="lead">
              <h3>Adblock Plus</h3>
              Go to the <a target="_blank" href="/">gaiamission.org</a>. Click on the ABP icon, then click on "Enabled on this site" in order to Disable. Refresh the page.
            </p>
            <img src="https://gaiamissioncdn.org/images/help/abp-404px.gif" alt=""/>
            <p className="lead">
              <h3>Adblock</h3>
              Go to the <a target="_blank" href="/">gaiamission.org</a>. Click on the AdBlock icon, then click on "Don't run on this page" in order to Disable. Refresh the page.
            </p>
            <img src="https://gaiamissioncdn.org/images/help/adblock-404px.gif" alt=""/>
            <p className="lead">
              <h3>uBlock</h3>
              Go to the <a target="_blank" href="/">gaiamission.org</a>. Click on the uBlock icon, then click the big blue "Power" button in order to Disable. Refresh the page.
            </p>
            <img src="https://gaiamissioncdn.org/images/help/ublock-404px.gif" alt=""/>
            <p className="lead">
              <h3>Ghostery</h3>
              Go to the <a target="_blank" href="/">gaiamission.org</a>. Click on the Ghostery icon, then click "Trust Site" in the lower left corner. Refresh the page.
            </p>
            <img src="https://gaiamissioncdn.org/images/help/ghostery-404px.gif" alt=""/>
          </div>
        </div>
      </ScrollableScene>
    );
  }
}

export default injectThemeHelpers(AdBlockHelp);
