import React from 'react';
import ScrollableScene from 'components/ScrollableScene';
import HeaderLogo from 'components/HeaderLogo';

const PrivacyPage = props => (
  <React.Fragment>
    <div className="header-section">
      <HeaderLogo></HeaderLogo>
    </div>

    <ScrollableScene className="privacy-page">
      <div className="row section-header">
        <div className="col-full">
          <h1 className="display-1">Privacy</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-full">
          <h2>PRIVACY POLICY</h2>
          <p>
            You will be informed each time our Site asks for personal
            information regarding how we intend to use your information. We may
            also have access to and collect some other personal information and
            data that is required to enable you to access the Site and its
            functionalities (cf. your IP address). GaiaMission.org may use such
            personal information to measure visitor usage and interest, and help
            us improve the content and functionality of our Site. Unless a user
            has opted out by modifying his/her preferences, upon registration,
            we may use any e-mail address that we are provided with to notify
            users of the existence of new contents in areas concerning which
            they have expressed an interest. You may request that we stop
            sending you such notices, at any time thereafter, by following the
            instructions mentioned in our e-mails. GaiaMission.org uses personal
            information collected from cookies and IP addresses as described
            below. Information which we do collect about our Site’s users is
            stored at our offices at 6200 Taschereau, suite 301 Brossard, QC,
            J4W 3J8 CANADA, and on servers hosted at national-net.com.
          </p>
          <h2>INFORMATION SHARING AND DISCLOSURE</h2>
          <p>
            GaiaMission.org does not and will not rent, sell, or share personal
            information about you with other people or non-affiliated companies,
            except to provide products or services you have requested, with your
            permission, or under the following circumstances: From time to time,
            we may provide some of the personal information that we collected to
            trusted business partners that work on behalf of or with
            GaiaMission.org under confidentiality agreements which cover our
            users’ personal information as well as guarantees that our partner
            provides the same level of protection to your information as our
            organization does. As the case may be, such business partners may
            use your personal information to help GaiaMission.org communicate
            with you about our offers and those of our marketing partners.
            However, our business partners do not have any independent right to
            share or otherwise use your information; We may also share your
            personal information if we believe it is necessary to share
            information in order to investigate, prevent, or take action
            regarding illegal activities, suspected fraud, situations involving
            potential threats to the physical safety of any person, violations
            of GaiaMission.org’s terms of use, or as otherwise required by law;
            We may finally transfer information about you if GaiaMission.org is
            acquired by or merged with another company. In this event,
            GaiaMission.org will notify you before information about you is
            transferred and becomes subject to a different privacy policy.
          </p>
          <h2>COOKIES AND IP ADDRESSES</h2>
          <p>
            Cookies are pieces of information that a web site transfers to your
            computer’s hard disk for record-keeping purposes. Cookies in and of
            themselves do not personally identify users, although they can be
            used to identify a user’s computer. Most browsers are initially set
            up to accept cookies. If you prefer, you can set your browser to
            refuse cookies. However, if you do this, you may not be able to take
            full advantage of our Site. For example, our Site uses cookies to
            make it easier for users to download graphics from the site. We may
            also use cookies to collect demographic and profile data for
            purposes of delivering content specific to your interests through
            the Site’s interface. An IP address is used by your computer every
            time it connects to the Internet, which is why this number can be
            used by Web sites to identify your computer. IP addresses are
            automatically collected by our Site, as part of demographic and
            profile data (known as “traffic data”), so that such data (such as
            the Web pages that you request through our Site) can be sent to you.
            We also use your IP address to help diagnose problems with our
            server and to administer the Site. GaiaMission.org will not use your
            IP address to attempt to identify you personally, nor to build a
            profile concerning who you are and what you search through our Site,
            otherwise that to provide you with the services and subscriptions
            that you request through registration or use of the Site.
          </p>
          <h2>ADVERTISERS</h2>
          <p>
            From time to time, we may use an outside advertising provider to
            display ads on our Site. Such ads may contain or use cookies. While
            we use cookies in other parts of our Web Site, cookies received with
            banner ads, as the case may be, are collected and used by our
            advertising provider. Please be advised that aside from allowing
            advertising providers to place such ads on our Site, we do not
            control nor have access to the information contained in or generated
            from the cookies placed on your computer by our advertising
            providers. External Links By its very nature, our Site contains
            links to third-party Web sites. Please be aware that we cannot
            control and are not responsible for the information collection
            practices of third-party Web sites. We encourage you to review and
            understand the privacy policies of these Web sites before visiting
            them or providing any information to them.{' '}
          </p>
          <h2>CONFIDENTIALITY AND SECURITY</h2>
          <p>
            Subject to the foregoing, we limit access to your personal
            information to employees of GaiaMission.org who we reasonably
            believe need to come into contact with such information either in
            order to do their jobs or to allow us to provide requested products
            and services to you. We have physical, electronic, and procedural
            safeguards that comply with federal regulations to protect and
            restrict access to personal information about the users of our Site.
          </p>
          <h2>CONTACTING THE SITE</h2>
          <p>
            If you have any questions about this privacy statement, the personal
            information practices of this Site, or your dealings with this Site,
            please contact{' '}
            <a href="mailto:info@gaiamission.org">info@gaiamission.org</a> by
            email, or customer support by regular mail at GaiaMission, 6200
            Taschereau, suite 301 Brossard, QC, J4W 3J8 CANADA.
          </p>
        </div>
      </div>
    </ScrollableScene>
  </React.Fragment>
);

export default PrivacyPage;
