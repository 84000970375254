import React from 'react';

import Listingheader from '../Listingheader';
import ResultLine from '../ResultLine';

import './style.css';

const OrganicResults = props => (
  <div>
    <Listingheader keyword={props.keyword} hits={props.totalHits} />
    <div className="sr-web-result">
      {props.listing.map((entry, index) => {
        return (
          <ResultLine
            key={index}
            title={entry.title}
            siteHost={entry.siteHost}
            description={entry.description}
            clickUrl={entry.clickUrl}
          />
        );
      })}
    </div>
  </div>
);

export default OrganicResults;
