import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { getSelectedMission } from 'redux/reducers';
import { missionSelect } from '../../views/Landing/pages/Header/redux/actions';
import { getMissionDataById } from 'common/MissionAPI';

const mapStateToProps = state => ({
  selectedMission: getSelectedMission(state),
});
const mapDispatchToProps = { missionSelect };

export function injectThemeHelpers(WrappedComponent) {
  const WrapperTheme = class extends React.Component {
    componentWillMount() {
      const queryParams = queryString.parse(this.props.location.search);
      let mid = queryParams['mid'] || null;
      if (getMissionDataById(mid)) {
        this.props.missionSelect(mid);
      }
    }

    render(){
      return <WrappedComponent {...this.props}></WrappedComponent>
    }
  };

  return connect(mapStateToProps, mapDispatchToProps)(WrapperTheme);
}

