import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ScrollableScene from 'components/ScrollableScene';
import './style.css';

class Mission extends Component {
  render() {
    return (
      <ScrollableScene id="mission" className="s-mission">
        <div className="row section-header" data-aos="fade-up">
          <div className="col-full">
            <h3 className="subhead">
              <FormattedMessage id="app.menu.our-mission"></FormattedMessage>
            </h3>
            <h1 className="display-1">
              <FormattedMessage id="app.section.our-mission.header1"></FormattedMessage>
            </h1>
          </div>
        </div>

        <div className="row" data-aos="fade-up">
          <div className="col-full">
            <p className="lead">
              <FormattedMessage id="app.section.our-mission.paragraph1"></FormattedMessage>
            </p>
          </div>
        </div>

        <div className="row mission-list block-1-3 block-m-1-2 block-tab-full">
          <div className="col-block mission-item " data-aos="fade-up">
            <div
              className="mission-icon mission-icon--brand-identity"
              style={{ color: '#8fbf4d' }}
            >
              <a href="/?mid=forest" style={{ color: '#8fbf4d' }}>
                <i className="las la-tree" aria-hidden="true"></i>
              </a>
            </div>
            <div className="mission-text">
              <h3 className="h4">
                <a href="/?mid=forest" style={{ color: '#8fbf4d' }}>
                  Rainforest
                </a>
              </h3>
              <p>
                The earth’s ecosystem is disrupted by large-scale lumber
                harvesting. When rainforests are cut down, the animals and
                plants that depend on the forests are also destroyed, not to
                mention some species are also at risk of being made extinct.
              </p>
            </div>
          </div>

          <div className="col-block mission-item" data-aos="fade-up">
            <div className="mission-icon" style={{ color: '#50a7b8' }}>
              <a href="/?mid=ocean" style={{ color: '#50a7b8' }}>
                <i className="las la-water" aria-hidden="true"></i>
              </a>
            </div>
            <div className="mission-text">
              <h3 className="h4">
                <a href="/?mid=ocean" style={{ color: '#50a7b8' }}>
                  Ocean
                </a>
              </h3>
              <p>
                Affecting the marine life, plastic pollution is one of the
                world’s biggest environmental issues. Toxic chemicals also
                affect the food chain, which in turn hurt the mankind.
              </p>
            </div>
          </div>

          <div className="col-block mission-item" data-aos="fade-up">
            <div className="mission-icon" style={{ color: '#f89337' }}>
              <a href="/?mid=wildlife" style={{ color: '#f89337' }}>
                <i className="las la-hippo" aria-hidden="true"></i>
              </a>
            </div>
            <div className="mission-text">
              <h3 className="h4">
                <a href="/?mid=wildlife" style={{ color: '#f89337' }}>
                  Wildlife
                </a>
              </h3>
              <p>
                Animals and plants are part of a wider natural environment that
                provide food, shelter, water for other wildlife and people. We
                have the responsibility to improve living conditions and
                livelihoods of the wildlife.
              </p>
            </div>
          </div>

          <div className="col-block mission-item" data-aos="fade-up">
            <div className="mission-icon" style={{ color: '#7c716b' }}>
              <a href="/?mid=animals" style={{ color: '#7c716b' }}>
                <i className="las la-dog" aria-hidden="true"></i>
              </a>
            </div>
            <div className="mission-text">
              <h3 className="h4">
                <a href="/?mid=animals" style={{ color: '#7c716b' }}>
                  Animals
                </a>
              </h3>
              <p>
                Every day millions of animals are suffering around the world
                from captivity, neglect and domestic abuse. Innocent animals
                need our tireless help to lead them to safe and natural lives.
              </p>
            </div>
          </div>

          <div className="col-block mission-item" data-aos="fade-up">
            <div className="mission-icon" style={{ color: '#b48f11' }}>
              <a href="/?mid=tree" style={{ color: '#b48f11' }}>
                <i className="las la-seedling" aria-hidden="true"></i>
              </a>
            </div>
            <div className="mission-text">
              <h3 className="h4">
                <a href="/?mid=tree" style={{ color: '#b48f11' }}>
                  Tree Planting
                </a>
              </h3>
              <p>
                Trees filter clean air, help reduce climate change, provide
                fresh drinking water, and create homes for plants and animals.
                Planting trees provide so many benefits to our daily lives and
                can help save the Earth from deforestation.{' '}
              </p>
            </div>
          </div>

          {/* <div className="col-block mission-item" data-aos="fade-up">
            <div className="mission-icon" style={{ color: '#d84646' }}>
              <a href="/?mid=coral" style={{ color: '#d84646' }}>
                <i className="las la-asterisk" aria-hidden="true"></i>
              </a>
            </div>
            <div className="mission-text">
              <h3 className="h4">
                <a href="/?mid=coral" style={{ color: '#d84646' }}>
                  Coral Reef
                </a>
              </h3>
              <p>
                As one of the 7 natural wonders of the world, the coral reef is
                under major threats of extreme climate change, poor water
                quality, coastal development and illegal fishing. We are
                responsible to preserve the largest living structure on the
                planet.
              </p>
            </div>
          </div> */}
        </div>
      </ScrollableScene>
    );
  }
}

export default Mission;
