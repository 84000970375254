import React from 'react';
import ListingLink from '../ListingLink';

import './style.css';

const ListingHost = props => (
  <ListingLink
    className="sr-host"
    target={props.target}
    text={props.bidded ? props.text : props.target}
  ></ListingLink>
);

export default ListingHost;
