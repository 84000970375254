import React, { Component } from 'react';
import ScrollableScene from 'components/ScrollableScene';
import HeaderLogo from 'components/HeaderLogo';
import Modal from 'react-awesome-modal';
import { injectThemeHelpers } from 'components/MissionTheme';

import './style.css';

const uninstallReasons = [
  {
    id: 'notuseful',
    text: "I don't find it to be useful or what I expected",
  },
  {
    id: 'layout',
    text: "I don't like the product layout",
  },
  {
    id: 'howitworks',
    text: "I don't understand how the product works",
  },
  {
    id: 'notinstall',
    text: "I don't remember installing this extension",
  },
  {
    id: 'notneedit',
    text: "I don't need to use it very often",
  },
];

class Uninstall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
    };
  }

  onSubmit = e => {
    e.preventDefault();
    const target =
      'https://uninstall.mystart.com/index.php/?' + this.serialize(e.target);
    fetch(target).finally(() => {
      this.openDialog();
    });
  };

  serialize(form) {
    return [].slice
      .call(form.querySelectorAll('[name]'))
      .reduce(function(carry, input) {
        const value = input.type === 'checkbox' ? input.checked : input.value;
        return carry + '&' + input.name + '=' + encodeURIComponent(value);
      }, '');
  }

  openDialog = () => {
    this.setState({
      dialogVisible: true,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogVisible: false,
    });
    document.location = '/';
  };

  render() {
    const installUrl = this.props.selectedMission.ext.url;
    return (
      <ScrollableScene id="uninstall" className="s-uninstall parallax">
        <div className="header-section">
          <HeaderLogo></HeaderLogo>
        </div>
        <div className="row section-header">
          <div className="col-full">
            <h1 className="display-1" style={{ fontSize: '3.6rem' }}>
              We are sad to see you go!
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-full">
            <p className="lead">Please tell us why you're leaving:</p>
            <form
              method="get"
              className="uninstall-form"
              onSubmit={this.onSubmit}
            >
              {uninstallReasons.map((reason, index) => {
                return (
                  <React.Fragment>
                    <label key={index} className="input-container">
                      {reason.text}
                      <input type="checkbox" name={reason.id} />
                      <span className="checkmark"></span>
                    </label>
                    <br />
                  </React.Fragment>
                );
              })}
              <input type='hidden' name='type' value='feedback' />
              <input type='hidden' name='one_id' value={this.props.selectedMission.ext.oneId} />
              <input type='hidden' name='uuid' value={this.props.selectedMission.ext.id} />
              <textarea cols="48" name="txtother" maxLength={500}></textarea>
              <button type="submit">Send</button>
            </form>
            <p className="lead">
              <a target="_blank" rel="noopener noreferrer" href={installUrl}>
                Oops! I want this extension back
              </a>
            </p>
          </div>
        </div>
        <Modal
          visible={this.state.dialogVisible}
          width="400"
          height="240"
          effect="fadeInDown"
          onClickAway={this.closeDialog}
        >
          <div className="uninstall-dialog">
            <h3>Thank you for your feedback</h3>
            <p>We hope to see you again soon!</p>
            <button onClick={this.closeDialog}>Close</button>
          </div>
        </Modal>
      </ScrollableScene>
    );
  }
}

export default injectThemeHelpers(Uninstall);
