import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ScrollableScene from 'components/ScrollableScene';

//import MissionStats from './components/MissionStats';

import './style.css';

class HowItWorks extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <ScrollableScene id="howitworks" className="s-howitworks">
        <div className="row section-header">
          <div className="col-full">
            <h3 className="subhead"><FormattedMessage id='app.menu.how-it-works'></FormattedMessage></h3>
            <h1 className="display-1">
              {this.props.selectedMission.how.title}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-full">
            <p className="lead">
              {formatMessage({ id: this.props.selectedMission.how.text1 })}. <a target='_blank' rel='noopener noreferrer' href={this.props.selectedMission.ext.url}><FormattedMessage id='app.section.how-it-works.download'></FormattedMessage></a> <FormattedMessage id='app.section.how-it-works.paragraph1'></FormattedMessage>
            </p>
          </div>
        </div>
        <div
          className="row stats block-1-3 block-m-1-2 block-mob-full mission-how"
          data-aos="fade-up"
        >
          <div className="col-block mission-how-col">
            <img
              src={
                'images/themes/avatars/search/gaia_search_' +
                this.props.selectedMission.id +
                '.svg'
              }
              alt="Author"
              className="mission__avatar"
            />
            <div className="mission-how-text">
              <FormattedMessage id='app.section.how-it-works.step1'></FormattedMessage>
            </div>
          </div>
          <div className="col-block mission-how-col">
            <div className="mission-how-text">
              <FormattedMessage id='app.section.how-it-works.step2'></FormattedMessage>
            </div>
            <img
              src={
                'images/themes/avatars/search/gaia_results_' +
                this.props.selectedMission.id +
                '.svg'
              }
              alt="Author"
              className="mission__avatar"
            />
          </div>
          <div className="col-block mission-how-col">
            <img
              src={this.props.selectedMission.avatar}
              alt="Author"
              className="mission__avatar"
            />
            <div className="mission-how-text">
              {this.props.selectedMission.how.text2}
            </div>
          </div>
        </div>
        { /* <MissionStats stats={this.props.selectedMission.stats} /> */}
      </ScrollableScene>
    );
  }
}

export default injectIntl(HowItWorks);
