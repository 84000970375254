import React from 'react';
import classnames from 'classnames';

const ListingLink = props => (
  <a
    className={classnames(props.className)}
    target="blank"
    rel="noopener noreferrer"
    href={props.target}
    dangerouslySetInnerHTML={{ __html: props.text }}
  ></a>
);

export default ListingLink;
