import React from 'react';
import ScrollableScene from 'components/ScrollableScene';
import HeaderLogo from 'components/HeaderLogo';

const EulaPage = props => (
  <React.Fragment>
    <div className="header-section">
      <HeaderLogo></HeaderLogo>
    </div>
    <ScrollableScene className="privacy-page">
      <div className="row section-header">
        <div className="col-full">
          <h1 className="display-1">Eula</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-full">
          <h2>END USER LICENSE AGREEMENT (EULA)</h2>
          <p>
            PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY. THIS AGREEMENT IS
            MEANT TO APPLY TO ANY AND ALL ONLINE CONTENT AND SERVICES, INCLUDING
            ANY SOFTWARE PRODUCTS OF ANY KIND SUCH AS PLUGINS, EXTENSIONS, NEW
            TAB AND APPS (COLLECTIVELY “Tools”) THAT MAY BE DISTRIBUTED OR MADE
            AVAILABLE BY GAIA MISSION. (“GAIAMISSION”) IN ASSOCIATION WITH THIS
            DOCUMENT AND THE FOLLOWING TERMS OF USE. BY DOWNLOADING, ACCESSING,
            INSTALLING AND/OR ACTIVATING ANY OF OUR TOOLS, YOU (THE USER) AGREE
            TO COMPLY WITH THE FOLLOWING TERMS OF USE. UPDATES: IN THE CASE OF
            OUR SOFTWARE PRODUCTS, ONCE INSTALLED, OUR SOFTWARE WILL
            AUTOMATICALLY LAUNCH EVERY TIME YOU EXECUTE YOUR COMPUTER’S INTERNET
            BROWSER PROGRAM. By agreeing to be bound by these terms of use,
            GAIAMISSION grants you the following limited rights to install and
            use our Tools: You may use the Tools for personal use, on a single
            computer per legitimate copy which you download from GAIAMISSION. If
            you wish to use the Tools for commercial use or if you wish to
            redistribute the Tools to others, you will require the prior express
            written authorization of GAIAMISSION; You may not otherwise
            reproduce, distribute, transmit, print or transfer information
            related to Tools.
          </p>
          <h2>PRIVACY NOTICE</h2>
          <p>
            BY PROCEEDING TO INSTALL ANY OF OUR TOOLS, YOU ACKNOWLEDGE AND
            ACCEPT THAT, UPON INSTALLATION, OUR TOOLS MAY MODIFY THE SETTINGS OF
            YOUR BROWSER CONCERNING, “ADDRESS BAR SEARCH”, “NEW TAB” AND
            “DEFAULT BROWSER SEARCH”. DEPENDING ON THE NATURE OF THE TOOL AT
            ISSUE, OUR TOOLS MAY FURTHER MODIFY SEARCH RESULT PAGES GENERATED
            THROUGH YOUR COMPUTER’S WEB BROWSER, AS DESCRIBED UNDER “SERVICES”
            BELOW. FURTHERMORE, ONCE INSTALLED, OUR TOOLS MAY COMMUNICATE
            CERTAIN BASIC USAGE INFORMATION TO GAIAMISSION STATS SYSTEM TO HELP
            GAIAMISSION COMPILE GLOBAL STATISTICS CONCERNING USAGE OF OUR TOOLS
            BY USERS (FOR EXAMPLE CONCERNING THE NUMBER OF INSTALLATIONS AND
            UNIQUE USERS, THE NUMBER OF CLICKS WHICH THE TOOLS RECEIVES, THE
            NUMBER OF SEARCHES PERFORMED BY USERS, ETC.). SUCH STATISTICAL
            INFORMATION WILL NOT INCLUDE ANY PERSONALLY IDENTIFIABLE INFORMATION
            REGARDING USERS. Users are invited to consult our complete privacy
            policy, as displayed on our Web site at www.GaiaMission.org.
          </p>
          <h2>SERVICES</h2>
          <p>
            Our online services rendered through or in association with our
            Tools are generally free of charge but may require users of our
            Tools to receive advertisement in the form of sponsored links and/or
            search results.
          </p>
          <h2>UNINSTALL</h2>
          <p>
            You may uninstall our Tools at any time and with no limitations,
            either by using the uninstaller provided with the Tools (as the case
            may be), or by using the standard uninstall procedures offered with
            your computer’s operating system or Web browser.
          </p>
          <h2>PERSONAL IDENTIFIABLE INFORMATION (PII)</h2>
          <p>
            Our Tools do collect the users’ IP addresses. The purpose of
            collecting this data is to enable proper geographic localization.
            While the IP address is stored for backup purposes, GAIAMISSION does
            not use this information for any other purpose. Other than the IP
            address, no other personal identifiable information is being
            collected. Aggregate and non-personally identifiable information on
            user activity based on views of merchant web sites, click-throughs
            of offer links, and conversions of offers may be shared with
            unaffiliated third parties: (1) for the purpose of documenting site
            activity; (2) to help advertisers and publishers serve and manage
            ads across the web and on GAIAMISSION and third party apps; (3) to
            better understand consumers, audiences, and market trends through
            predictive modeling and analytics. EVEN IF YOU ACCEPT THIS
            AGREEMENT, IT IS STRICTLY PROHIBITED TO Modify any of our Tools,
            whether in part or in whole; Decompile or reverse engineer any of
            our Tools. SHOULD YOU FAIL TO COMPLY WITH ANY OF THE ABOVE, YOUR
            RIGHT TO USE OUR TOOLS WILL BE AUTOMATICALLY TERMINATED. THIS
            TERMINATION WILL BE IN ADDITION TO ANY CRIMINAL, CIVIL OR OTHER
            REMEDIES WHICH GAIAMISSION MAY SEEK. THE USER MAY CANCEL HIS LICENSE
            TO USE OUR TOOLS BY UNINSTALLING AND DESTROYING OR ERASING ALL
            COPIES OF OUR TOOLS UNDER HIS CONTROL.
          </p>
          <h2>EXCLUSION OF WARRANTY</h2>
          <p>
            OUR TOOLS ARE PROVIDED UNDER LICENSE ON AN “AS IS” BASIS, WITHOUT
            WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING WITHOUT
            LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE. THE ENTIRE RISK AS TO THE RESULTS AND
            PERFORMANCE OF OUR TOOLS IS BORNE BY THE USER. GAIAMISSION MAY, AT
            ANY TIME, TERMINATE THIS AGREEMENT BY NOTIFYING THE USER OF OUR
            TOOLS.
          </p>
          <h2>LIMITATION OF LIABILITY</h2>
          <p>
            IN NO EVENT SHALL GAIAMISSION, OR ANY THIRD PARTY
            INVOLVED IN THE CREATION, PRODUCTION, OR DELIVERY OF OUR TOOLS BE
            LIABLE UNDER ANY CIRCUMSTANCES FOR ANY DIRECT OR INDIRECT DAMAGES
            INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF BUSINESS PROFITS,
            BUSINESS INTERRUPTION, LOSS OF DATA OR OTHER PECUNIARY LOSS INCURRED
            BY THE USER AND ARISING OUT OF THE USE OR INABILITY TO USE OUR
            TOOLS, EVEN IF GAIAMISSION HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES.
          </p>
          <h2>PROPERTY</h2>
          <p>
            The names of our Tools are trademark of GAIAMISSION and are
            protected by international laws on trademarks and intellectual
            property. Use of the products’ names is strictly forbidden unless
            it’s used with the express written permission of GAIAMISSION. Our
            Tools, including its code, documentation, appearance, structure, and
            organization is an exclusive product of GAIAMISSION, which retains
            all intellectual property rights concerning its Tools, its copies,
            modifications, or merged parts, including any copyrights and
            invention rights.
          </p>
          <h2>GOVERNING LAW AND FORUM</h2>
          <p>
            This Agreement will be governed and interpreted according to the
            laws applicable within the Province of Quebec, Canada and Canadian
            laws (excluding the application of its conflicts of law rules). Any
            judicial proceedings relating to this Agreement must be presented in
            the Judicial District of the Province of Quebec, Canada, to the
            exclusion of any other courts or jurisdiction.
          </p>
          <h2>PARTNERS</h2>
          <p>
            As the case may be, your dealings with partner Web sites and
            businesses and your participation in their promotions are solely
            between you and such partners and businesses, and your participation
            may be subject to certain terms and conditions determined by such
            third parties. Please indicate your understanding and agreement to
            this Agreement, by clicking the button below. If you do not accept
            the terms and conditions of this agreement, you may “UNINSTALL” the
            product or click the “CANCEL” button but you will not be allowed to
            use the Tools.
          </p>
        </div>
      </div>
    </ScrollableScene>
  </React.Fragment>
);

export default EulaPage;
