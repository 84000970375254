import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import ScrollableScene from 'components/ScrollableScene';
import { connect } from 'react-redux';
import { getSelectedMission } from 'redux/reducers';
import partnersList from './partners.json';

import './style.css';

class PartnerComponent extends Component {
  render() {
    const partnerInfo = partnersList[this.props.selectedMission.id];
    return (
      <ScrollableScene id="partner">
        <div className="row section-header" data-aos="fade-up">
          <div className="col-full">
            <h3 className="subhead">
              <FormattedMessage id="app.menu.our-partner"></FormattedMessage>
            </h3>
            <h1 className="display-1"><FormattedMessage id="app.section.our-partner.header1"></FormattedMessage></h1>
          </div>
        </div>
        <div className="row" data-aos="fade-up">
          <div className="col-full">
            <p className="lead"><FormattedMessage id="app.section.our-partner.paragraph1"></FormattedMessage>
            <br /><br />
              {partnerInfo.text}
            </p>
            <div className='partner-card'>
              <img className='partner-card-logo' src={partnerInfo.logo} alt="" />
              <div className='partner-card-info'>
                <div className='partner-card-name'>{partnerInfo.name}</div>
                <a rel="noopener noreferrer" target='_blank' className='partner-card-link' href={partnerInfo.link}>{partnerInfo.linkText}</a>
              </div>
            </div>
          </div>
        </div>
      </ScrollableScene>
    );
  }
}

const mapStateToProps = state => ({
  selectedMission: getSelectedMission(state),
});

const Partner = connect(mapStateToProps)(PartnerComponent);

export default Partner;
