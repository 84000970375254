import { combineReducers } from 'redux';
import { MISSION_SELECT } from './types';
import { getMissionData } from 'common/MissionAPI';

const defaultState = getMissionData();

function selectMissionId(state = 'forest', action) {
  switch (action.type) {
    case MISSION_SELECT:
      return action.payload.selectedMissionId;
    default:
      return state;
  }
}

function missionsList(state = defaultState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default combineReducers({
  selectedMissionId: selectMissionId,
  missionsList: missionsList,
});
