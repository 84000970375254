import React from 'react';
import classnames from 'classnames';

const Select = props => (
  <select
    value={props.value}
    className={classnames(props.className)}
    onChange={e => props.selectAction(e.target.value)}
  >
    {props.items.map(item => (
      <option key={item.id} value={item.id}>
        {item.title}
      </option>
    ))}
    ;
  </select>
);

export default Select;
