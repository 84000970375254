import React from 'react';
import Select from 'components/Select';
import './style.css';

const MissionSelector = props => (
  <React.Fragment>
    <span className='mission-selector'>MISSION</span>
    <Select
      className="theme-selector"
      items={props.missionsList}
      value={props.selectedMissionId}
      selectAction={props.missionSelect}
    ></Select>
  </React.Fragment>
);

export default MissionSelector;
