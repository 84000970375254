import React from 'react';
import ListingTitle from '../ListingTitle';

import './style.css';

const SiteLinks = props => (
  <div className="sr-sitelinks-container">
    {
      props.links.map((row, i) =>
        <div className="sr-sitelinks-row">
          {
            row.map((link, j) =>
              <div className="sr-sitelinks-cell">
                <ListingTitle target={link.url} text={link.text}></ListingTitle>
              </div>
            )
          }
        </div>
      )
    }
  </div>
);

export default SiteLinks;
