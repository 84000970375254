import React from 'react';
import ListingLink from '../ListingLink';

import './style.css';

const ListingTitle = props => (
  <ListingLink
    className="sr-title"
    target={props.target}
    text={props.text}
  ></ListingLink>
);

export default ListingTitle;
