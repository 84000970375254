import React, { Component } from 'react';
import Odometer from 'react-odometerjs';

import 'styles/vendor/odometer-theme-default.css';
import './style.css';

export default class MissionCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      displayCounter: false
    };
  }

  componentDidMount() {
    this.count = setInterval(() => {
      this.counterTimer();
    }, this.props.rate * 1000);
  }

  componentWillReceiveProps(nextProps) {
    clearInterval(this.count);
    this.setState({ value: nextProps.value });
    this.count = setInterval(() => {
      this.counterTimer();
    }, nextProps.rate * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.count);
  }

  counterTimer() {
    this.setState({ value: this.state.value + 1 });
  }

  render() {
    return (
      <div className="counter-container">
        {this.state.displayCounter && (
          <React.Fragment>
          <div className="counter-value">
            <Odometer value={this.state.value} format="(,ddd)" />{' '}
            <span className="counter-unity">
              {this.props.unity}
              {this.props.sup ? <sup>{this.props.sup}</sup> : ''}
            </span>
          </div>
          <div className="counter-text">
            <span id="counter-text">{this.props.text}</span>{' '}
            <span className="counter-learn-more">
              (<a href="/#howitworks">learn more</a>)
          </span>
          </div>
          </React.Fragment>
        )}
        {!this.state.displayCounter && (
          <div className="counter-text">
            <span id="counter-text">{this.props.description}</span>{' '}
            <span className="counter-learn-more">
              (<a href="/#howitworks">learn more</a>)
          </span>
          </div>
        )}
      </div>
    );
  }
}
