import React, { Component } from 'react';

import './style.css';

export default class AdblockerMessage extends Component {
  refresh = () => {
    window.open('https://gaiamission.org/help/ab');
  };

  render() {
    return (
      <div className="ab-message-box">
        <h3>{this.props.selectedMission.counter.description}</h3>
        <p>
          We have detected that you are using an ad blocker. Gaia MISSION
          donations come from revenue generated by Advertising.
          <br />
          Please disable the ad blocker for gaiamission.org and click the
          refresh button below.
        </p>
        <button onClick={this.refresh}>Disable Ad Block</button>
      </div>
    );
  }
}
