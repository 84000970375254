import { Component } from 'react';
import { checkAb } from 'common/utils';

export default class AdblockDetected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detected: false,
    };
  }

  componentDidMount() {
    checkAb()
      .then(() => {
        this.setState({ detected: false });
      })
      .catch(e => {
        this.setState({ detected: true });
      });
  }

  render = () => (this.state.detected ? this.props.children : null);
}
